import { ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

interface Props {
  children?: ReactNode;
  // any props that come into the component
}

export default function Layout({ children }: Props) {
  Sentry.setUser(null);
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
}
