import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';

export function HPCard() {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder data-testid="hp-event-card">
      <Card.Section component="a" href="/events/grandslam">
        <Image
          src="/home/grandSlamBanner.png"
          height="auto"
          alt="GrandSlam Banner"
          width="100%"
        />
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>Brownells Grand Slam 2024</Text>
        <Badge color="pink" variant="light">
          New
        </Badge>
      </Group>

      <Button
        variant="light"
        color="green"
        component="a"
        href="/events/grandslam"
        fullWidth
        mt="md"
        radius="md"
      >
        Vai alla pagina
      </Button>
    </Card>
  );
}
