import { ReactElement } from 'react';
import { Matches } from 'components/Welcome/Matches';
import Head from 'next/head';
import { Hero } from '../components/Welcome/Hero';
import { Features } from '../components/Welcome/Features';
import Layout from '../layout/outside';
import { NextPageWithLayout } from './_app';

const Page: NextPageWithLayout = () => (
  <>
    <Hero />
    <Features />
    <Matches />
  </>
);

Page.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <Head>
        <title>Saurs</title>
        <meta
          name="description"
          content="Segui i tuoi progressi con le statistiche delle tue gare IPSC e IDPA"
          key="desc"
        />
      </Head>
      <Layout>{page}</Layout>;
    </>
  );
};

export default Page;
