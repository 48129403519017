import {
  // createStyles,
  Header,
  Group,
  // Button,
  // Divider,
  Box,
  // Burger,
  // Drawer,
  // ScrollArea,
} from '@mantine/core';
// import { useDisclosure } from '@mantine/hooks';
import Logo from '../Logo/Logo';

// const useStyles = createStyles((theme) => ({
//   link: {
//     display: 'flex',
//     alignItems: 'center',
//     height: '100%',
//     paddingLeft: theme.spacing.md,
//     paddingRight: theme.spacing.md,
//     textDecoration: 'none',
//     color: theme.colorScheme === 'dark' ? theme.white : theme.black,
//     fontWeight: 500,
//     fontSize: theme.fontSizes.sm,

//     [theme.fn.smallerThan('sm')]: {
//       height: 42,
//       display: 'flex',
//       alignItems: 'center',
//       width: '100%',
//     },

//     ...theme.fn.hover({
//       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
//     }),
//   },

//   subLink: {
//     width: '100%',
//     padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
//     borderRadius: theme.radius.md,

//     ...theme.fn.hover({
//       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
//     }),

//     '&:active': theme.activeStyles,
//   },

//   dropdownFooter: {
//     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
//     margin: -theme.spacing.md,
//     marginTop: theme.spacing.sm,
//     padding: `${theme.spacing.md} ${theme.spacing.xl}`,
//     paddingBottom: theme.spacing.xl,
//     borderTop: `1px solid ${
//       theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
//     }`,
//   },

//   hiddenMobile: {
//     [theme.fn.smallerThan('sm')]: {
//       display: 'none',
//     },
//   },

//   hiddenDesktop: {
//     [theme.fn.largerThan('sm')]: {
//       display: 'none',
//     },
//   },
// }));

export default function CustomHeader() {
  // const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  // const { classes, theme } = useStyles();

  return (
    <Box>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: '100%' }}>
          <Logo size="lg" />
          {/* <Group className={classes.hiddenMobile}>
            <Button component="a" href="/login" variant="default" data-testid="header-btn-login">
              Accedi
            </Button>
            <Button component="a" href="/login/register" data-testid="header-btn-register">
              Registrati
            </Button>
          </Group> */}
          {/* <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} data-testid="header-burger" /> */}
        </Group>
      </Header>

      {/* <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
          <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

          <Group position="center" grow pb="xl" px="md">
            <Button
              component="a"
              href="/login"
              variant="default"
              data-testid="header-mobile-btn-login"
            >
              Accedi
            </Button>
            <Button component="a" href="/login/register" data-testid="header-mobile-btn-register">
              Registrati
            </Button>
          </Group>
        </ScrollArea>
      </Drawer> */}
    </Box>
  );
}
