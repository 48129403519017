import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  Indicator,
} from '@mantine/core';
import Image from 'next/image';

const mockdata = [
  {
    title: 'IPSC',
    description:
      'Prende i risultati direttamente dai tuoi "Verify" e costruisce automaticamete un grafico con l&apos;andamento dei tuoi tempi e dei tuoi punteggi nel "long", "medium" e "short".',
    imageUrl: '/home/ipsc.png',
    imageW: 78,
    imageH: 100,
  },
  {
    title: 'IDPA',
    description:
      'Prende i risultati direttamente da Practiscore e costruisce un grafico coi tempi e i punti down su tutti i match più importanti italiani.',
    imageUrl: '/home/idpa.png',
    imageW: 270,
    imageH: 40,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export function Features() {
  const { classes } = useStyles();
  const features = mockdata.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} p="xl">
      <div style={{ width: feature.imageW }}>
        {feature.title === 'IDPA' ? (
          <Indicator color="indigo" inline label="Coming soon" size={16}>
            <Image
              alt="Logo"
              src={feature.imageUrl}
              width={feature.imageW}
              height={feature.imageH}
            />
          </Indicator>
        ) : (
          <Image alt="Logo" src={feature.imageUrl} width={feature.imageW} height={feature.imageH} />
        )}
      </div>
      <div>
        <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
          {feature.title}
        </Text>
        <Text size="sm" color="dimmed" mt="sm">
          {feature.description}
        </Text>
      </div>
    </Card>
  ));
  return (
    <Container size="lg" py="xl">
      <Group position="center">
        <Badge variant="filled" size="lg">
          SAURS
        </Badge>
      </Group>

      <Title order={2} className={classes.title} align="center" mt="sm">
        Statistiche delle gare IPSC e IDPA
      </Title>

      <Text color="dimmed" className={classes.description} align="center" mt="md">
        Prende automaticamente tutte le gare disputate dal tiratore e costruisce dei grafici con
        l&apos;andamento dei tempi, punti e margini di miglioramento
      </Text>

      <SimpleGrid cols={2} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
        {features}
      </SimpleGrid>
    </Container>
  );
}
