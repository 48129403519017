import { createStyles, Title, Container, Indicator, Text } from '@mantine/core';
import { HPCard } from './components/Card';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export function Matches() {
  const { classes } = useStyles();
  return (
    <Container size="lg" py="xl" data-testid="hp-event">
      <Title order={2} className={classes.title} align="center" mt="sm">
        <Indicator inline label="NEW" size={16}>
          Events
        </Indicator>
      </Title>
      <Text color="dimmed" className={classes.description} align="center" mt="md" mb={50} />
      <HPCard />
    </Container>
  );
}
