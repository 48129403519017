import { createStyles, Text } from '@mantine/core';

const useStyles = createStyles(() => ({
  logo: {
    fontFamily: 'Avenir Next Heavy, sans-serif',
  },
}));

export default function Logo({ size }: { size: string }) {
  const { classes } = useStyles();
  return (
    <Text
      variant="gradient"
      gradient={{ from: 'red', to: 'orange', deg: 45 }}
      weight={500}
      size={size}
      sx={{ lineHeight: 1 }}
      mr={3}
      className={classes.logo}
      component="a"
      href="/"
    >
      SAURS
    </Text>
  );
}
