import { createStyles, Container, Title, Text, rem } from '@mantine/core';
import Image from 'next/image';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },

  image: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  content: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    marginRight: `calc(${theme.spacing.xl} * 3)`,
    zIndex: 2,

    [theme.fn.smallerThan('md')]: {
      marginRight: 0,
    },
  },

  title: {
    color: theme.white,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: rem(500),
    fontSize: rem(48),

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      fontSize: rem(34),
      lineHeight: 1.15,
    },
  },

  description: {
    color: theme.white,
    opacity: 0.75,
    maxWidth: rem(500),

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },

  control: {
    paddingLeft: rem(50),
    paddingRight: rem(50),
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(22),

    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
  gradient: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7))',
  },
}));

export function Hero() {
  const { classes } = useStyles();
  return (
    <div className={classes.root} data-testid="hp-hero">
      <Image alt="Image Background" fill src="/home/hero_bkg.jpg" style={{ objectFit: 'cover' }} />
      <div className={classes.gradient} />
      <Container size="lg">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              <Text
                span
                inherit
                variant="gradient"
                gradient={{ from: 'red', to: 'orange', deg: 45 }}
              >
                S
              </Text>
              hooter{' '}
              <Text
                span
                inherit
                variant="gradient"
                gradient={{ from: 'red', to: 'orange', deg: 45 }}
              >
                A
              </Text>
              re Yo
              <Text
                span
                inherit
                variant="gradient"
                gradient={{ from: 'red', to: 'orange', deg: 45 }}
              >
                u R
              </Text>
              eady?{' '}
              <Text
                span
                inherit
                variant="gradient"
                gradient={{ from: 'red', to: 'orange', deg: 45 }}
              >
                S
              </Text>
              tandby!
            </Title>
          </div>
        </div>
      </Container>
    </div>
  );
}
